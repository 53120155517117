import {
  ComponentRef,
  EditorScriptFlowAPI,
  FlowEditorSDK,
  TFunction,
} from '@wix/yoshi-flow-editor';
import { EventType, gfppOriginStrings } from '@wix/platform-editor-sdk';

import { GfppAction, ReferralInfo } from '../../../../constants';
import {
  maybeCollapseHeaderContainer,
  openMemberSettingsBM,
} from '../../../../editor/services';
import { openManageAndNavigatePanel } from '../../panels';
import { openDisplayPanel, openPresetPanel } from '../../../common/panels';
import { openUnifiedManagerPanel } from '../../panels/open-panel';

const handleWidgetGfppEvent = async (
  editorSDK: FlowEditorSDK,
  flowAPI: EditorScriptFlowAPI,
  event: CustomEvent<{
    id: string;
    componentRef: ComponentRef;
    gfppOrigin: gfppOriginStrings;
  }>,
) => {
  switch (event.detail.id) {
    case GfppAction.OpenLayoutPanel: {
      return openPresetPanel(editorSDK, event, flowAPI);
    }
    case GfppAction.OpenMemberPrivacySettingsBM: {
      return openMemberSettingsBM(
        editorSDK,
        ReferralInfo.MembersPrivacyManageMemberPrivacyGfpp,
      );
    }
    case GfppAction.OpenDisplayPanel: {
      await maybeCollapseHeaderContainer(editorSDK);
      return openDisplayPanel(flowAPI, editorSDK, event);
    }
    case GfppAction.OpenUnifiedManagerPanel: {
      return openUnifiedManagerPanel(editorSDK, {
        initialTab: 'memberPageTabs',
      });
    }
    default: {
      break;
    }
  }
};

const handleComponentGfppEvent = (
  editorSDK: FlowEditorSDK,
  flowAPI: EditorScriptFlowAPI,
  event: CustomEvent<{
    id: string;
    componentRef: ComponentRef;
  }>,
) => {
  switch (event.detail.id) {
    case GfppAction.OpenManageAndNavigatePanel:
      return openManageAndNavigatePanel({
        editorSDK,
        event,
        t: flowAPI.translations.t as TFunction,
      });
    case GfppAction.OpenUnifiedManagerPanel: {
      return openUnifiedManagerPanel(editorSDK, {
        initialTab: 'memberPageTabs',
      });
    }
    default:
      break;
  }
};

export const addWidgetGfppClickedListener = async (
  editorSDK: FlowEditorSDK,
  flowAPI: EditorScriptFlowAPI,
): Promise<void> => {
  await editorSDK.addEventListener(EventType.widgetGfppClicked, (event) =>
    handleWidgetGfppEvent(editorSDK, flowAPI, event),
  );

  await editorSDK.addEventListener(EventType.componentGfppClicked, (event) =>
    handleComponentGfppEvent(editorSDK, flowAPI, event),
  );
};
