import {
  type EditorPlatformApp,
  type EditorSDK,
} from '@wix/editor-platform-sdk-types';
import type {
  ExperimentsBag,
  EditorReadyFn,
  FlowAPI,
  TFunction,
  ComponentRef,
} from '@wix/yoshi-flow-editor';
import { getAppManifestFactory } from './editor-script/app-manifest/app-manifest';
import {
  onWidgetAdded,
  onComponentDeleted,
  onSitePublished,
  onAppVisitedInDashboard,
} from './editor-script/event-handlers';
import { maybeInstallEcom } from './editor-script/ecom';
import { run } from './migration/migration';

let sdk: EditorSDK;
let appToken: string;
let flowApi: FlowAPI;
let t: TFunction;
let experiments: ExperimentsBag;
interface FormAppInterface extends Omit<EditorPlatformApp, 'editorReady'> {
  editorReady: EditorReadyFn;
}

const editorApp: FormAppInterface = {
  editorReady: async (editorSDK, token, platformOptions, _flowApi) => {
    flowApi = _flowApi;
    sdk = editorSDK as any;
    appToken = token;
    t = flowApi.translations.t as any;
    experiments = flowApi.experiments.all();

    flowApi.bi?.updateDefaults({
      target_account_id: platformOptions.initialAppData.userId,
    });

    await maybeInstallEcom({ sdk, flowApi });

    onAppVisitedInDashboard({ sdk, flowApi });

    onWidgetAdded({
      sdk,
      appToken,
      flowApi,
    });
    onComponentDeleted({
      sdk,
      bi: flowApi.bi || undefined,
      appToken,
    });
    onSitePublished({ sdk, appToken, bi: flowApi.bi || undefined });
  },
  getAppManifest: getAppManifestFactory(
    () => t,
    () => appToken,
    () => sdk,
    () => experiments,
    () => flowApi.httpClient,
    () => flowApi.fedops,
  ),
};

export const editorReady = editorApp.editorReady;
export const getAppManifest = editorApp.getAppManifest;

/* Open console and paste this script to run migration
(() => {
  const documentServices = frames.preview.contentWindow.documentServices;
  const appDefinitionId = '225dd912-7dea-4738-8688-4b8c6955ffc2';
  const migrationId = 'replace-widgets';
  documentServices.tpa.app.add(appDefinitionId, {
    callback: ({ comp }) => {
      documentServices.platform.migrate(appDefinitionId, {
        migrationId,
        widgetRef: comp,
      });
    },
  });
})();
*/
export const handleAction = async ({
  type,
  payload,
}: {
  type: string;
  payload: { migrationId: string; widgetRef: ComponentRef };
}) => {
  switch (type) {
    case 'migrate':
      if (payload.migrationId === 'replace-widgets') {
        await run(sdk, payload.widgetRef);
      }
      break;
    default:
      break;
  }
};
